@use './../assets/variables.scss' as vars;

div.input-block {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  div.calculator-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    @media screen and (max-width: 420px) {
      flex-direction: column;
      align-items: stretch;

      input[type="checkbox"] {
        align-self: flex-start;
      }
    }

    label:has(+ input[type="checkbox"]) {
      margin-top: 5px;
    }
  }
}

input[type="text"] {
  border: 1px solid vars.$color-light-gray;
  background-size: 15px;
  color: vars.$color-gray;
  font-weight: normal;
  margin-bottom: 1rem;
  padding: 5px 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type="checkbox"] {
  appearance: none;
  align-self: center;
  justify-self: start;
  background-color: vars.$color-white;
  margin: 0 0 1rem 0;
  color: vars.$color-gray;
  width: 32px;
  height: 32px;
  border: 1px solid vars.$color-light-gray;
  cursor: pointer;
  padding: 5px;

  &:checked {
    background-color: vars.$color-primary;
    background-image: url('/assets/icons/check-mark.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;

    svg {
      stroke: vars.$color-white;
    }
  }
}

button {
  border: 1px solid vars.$color-gray;
  border-radius: 0;
  color: vars.$color-dark-gray;
  background-color: vars.$color-white;
  padding: 14px 23px;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.14em;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .2s ease-out;

  &:hover {
    background-color: vars.$color-primary;
    color: vars.$color-white;
  }
}

div.calculation-block {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: 420px) {
    flex-direction: column;
    align-items: stretch;
  }
}

span {
  .positive {
    color: green;
  }

  .negative {
    color: red;
  }
}
