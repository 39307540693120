@use 'assets/variables';

@font-face {
  font-family: 'PwCHelveticaNeue';
  src: local('PwCHelveticaNeue'), url('./assets/fonts/HelveticaNeueLTW05-55Roman.woff2') format('woff2');
}

.calculation-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 1rem 2rem;

  font-family: PwCHelveticaNeue, Arial, sans-serif;
  font-size: 16px;
  line-height: 27.2px;
}

div.title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: 330px) {
    flex-direction: column;
    align-items: stretch;
  }
}
