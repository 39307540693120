@use './../assets/variables.scss' as vars;

select {
  border: 1px solid vars.$color-light-gray;
  background-size: 15px;
  color: vars.$color-gray;
  font-weight: normal;
  margin-bottom: 1rem;
  padding: 10px 30px 10px 10px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
